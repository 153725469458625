@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "TencentSans";
  src:
    url("assets/TencentSansW7.woff2") format("woff2"),
    url("assets/TencentSansW7.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "TencentSans";
  src:
    url("assets/TencentSansW3.woff2") format("woff2"),
    url("assets/TencentSansW3.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  background: #140c17;
  overflow: hidden;
}
body {
  margin: 0;
  font-family:
    TencentSans,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.orientation-hint {
  position: fixed;
  display: none;
  @media screen and (orientation: landscape) {
    z-index: 99999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #140c17;
    color: white;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.top-screen {
  top: 100%;
  top: 100vh;
  top: 100dvh;
}

.h-screen {
  height: 100%;
  height: 100vh;
  height: 100dvh;
}

// .bg-airplane {
//   background-image: url("assets/airplane.webp");
//   background-size: 3200% 100%;
//   background-position: 0 0;
//   animation: airplane 2s steps(31);
//   animation-fill-mode: forwards;
// }

.bg-airplane {
  background-image: url("assets/airplane.png");
  background-size: 4500% 100%;
  background-position: 0 0;
  animation: airplane 2.6s steps(44);
  animation-fill-mode: forwards;
}

// .bg-apple {
//   background-image: url("assets/apple.webp");
//   background-size: 4100% 100%;
//   background-position: 0 0;
//   animation: apple 1.68s steps(40);
//   animation-fill-mode: forwards;
// }

.bg-apple {
  background-image: url("assets/apple.png");
  background-size: 2800% 100%;
  background-position: 0 0;
  animation: apple 1.68s steps(27);
  animation-fill-mode: forwards;
}


// .bg-crown {
//   background-image: url("assets/crown.webp");
//   background-size: 2800% 100%;
//   background-position: 0 0;
//   animation: crown 2.26s steps(27);
//   animation-fill-mode: forwards;
// }

.bg-crown {
  background-image: url("assets/crown.png");
  background-size: 3900% 100%;
  background-position: 0 0;
  animation: crown 2.24s steps(38);
  animation-fill-mode: forwards;
}

// .bg-kite {
//   background-image: url("assets/kite.png");
//   background-size: 3500% 100%;
//   background-position: 0 0;
//   animation: kite 2.82s steps(34);
//   animation-fill-mode: forwards;
// }

.bg-kite {
  background-image: url("assets/kite.png");
  background-size: 4900% 100%;
  background-position: 0 0;
  animation: kite 2.8s steps(48);
  animation-fill-mode: forwards;
}

.swiper-3d .swiper-slide, .swiper-3d .swiper-cube-shadow {
  transform-style: flat !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@keyframes airplane {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -4400% 0;
  }
}

@keyframes apple {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -2700% 0;
  }
}

@keyframes crown {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -3800% 0;
  }
}

@keyframes kite {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -4800% 0;
  }
}

